.add-company-container {

    form {
        .company-card {
            background: #FFFFFF;
            border-radius: 8px;
            padding: 16px;
            margin-bottom: 10px;
    
            .title-text {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142D;
            }
    
            .row-deco {
                margin-bottom: 20px;
            }
        }

        .company-discriprion-wrapper{
            
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: '100%';

            .import-discription-wrapper {
                height: 184px;
                margin-right: 12px;
                width: 100%;
            }
    
            .import-image-wrapper {
                height: 184px;
                width: 184px;
                display: flex;
                justify-content: center;
    
                .import-image {
                    height: 152px;
                    width: 152px;
                }
            }
        }
        .admin-profile-picture{
            height: 40px;
            width: 40px;
            margin: 10px;
            margin-top: 23px;
        }

        .buttons {
            width: 131px;
        }
    }
}