.tab-button {
    border: none;
    background-color: white;
    padding: 8px;
    color: #687C97;
    width: 158px;
    height: 60px;
}

.settings-tab-selected {
    border-bottom: solid 1px red;
}
.tab-pane-wrapper {
    overflow-y: scroll;
    overflow-x: auto;
    height: calc(100vh - 260px);
}

.action-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 15px;
    margin-top: 12px;
}