.company-grid-header-text{
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #11142D;
    opacity: 0.6;
    margin-top: 4px;
}

.max-width-70 {
    max-width: 70px;
}

.max-width-32 {
    width: 32%;
}

.max-width-26 {
    width: 26%;
}

.max-width-15 {
    width: 15%;
}

.max-width-10 {
    width: 10%;
}