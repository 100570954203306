.welcome {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  padding-top: 27px;
}

.subMessage {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  width: 538px;
  height: 140px
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  padding: 0 120px 0;
  height: 100vh;
}

img {
  height: 120px;
  width: 120px;
}

.leftContainer {
  margin-right: 90px;
  margin-top: -4em;
}

.rightContainer {
  background: white;
  height: 720px;
  width: 565px;
  min-width: 565px;
  border-radius: 30px;
  color: #36455A;
}

.rightContents {
  padding: 88px;
}

.hello {
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
}

.helloSubText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  color: #6D7785;
}

.label {
  font-size: 18px;
  line-height: 27px;
  color: #36455A;
}

input {
  width: 100%;
  height: 42px;
  font-size: 18px;

  &:focus {
    outline: none;
  }

  &.is-valid {
    &:focus {
      border: 0 transparent;
      outline: 0;
    }
  }
}

.inputBorder {
  border-bottom: 0.793673px solid #F44059 !important;
}

.h-75px {
  height: 100px !important;
}

.mt-n30 {
  margin-top: -30px;
}

input:focus {
  outline: none;
}

/*input:focus + label {
  top: -15px;
}*/

input:focus .label {
  font-size: 12px;
}

.form-floating {
  input {
    height: 40px !important;
  }

  input:focus + label {
    top: -20px !important;
  }

  label {
    top: -20px !important;
  }

  .eye {
    position: absolute;
    margin-top: -5.6em;
    margin-left: 20.8em;
  }
}

.mt-82px {
  margin-top: 82px;
}

.mt-57px {
  margin-top: 57px;
}

.form-check {
  .form-check-input {
    border: 1px solid #6A6F7D;
    box-sizing: border-box;
    border-radius: 1px !important;
  }

  .form-check-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #6A6F7D;
  }
}

.forgot {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #6A6F7D;
  cursor: pointer;
}

.btn-danger {
  background-color: #F44059 !important;
  border-color: #F44059 !important;
  border-radius: 7.93673px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;

  &:hover {
    background-color: #ec3b54 !important;
    border-color: #ec3b54 !important;
  }
}

.eye {
  position: absolute;
  margin-top: -95px;
  margin-left: 333px;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}


@media screen and (max-device-width: 1400px) {
  .rightContainer {
    height: 550px;
    .rightContents {
      padding: 35px 80px 50px;
    }
    .mt-82px {
      margin-top: 60px;
    }
  }
}

.form-check-input:checked {
  background-color: #ec3b54 !important;
  border-color: #ec3b54 !important;
}