@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&display=swap');

.safe-color {
  color: #00B929;
}

.intermediary-color {
  color: #FFCC16;
}

.replace-color {
  color: #FF1637;
}

.safe-bg {
  background-color: #00B929;
}

.intermediary-bg {
  background-color: #FFCC16;
}

.replace-bg {
  background-color: #FF1637;
}

.search-main {
  input {
    border: 1px solid #F4F7F9;
    box-sizing: border-box;
    border-radius: 8px;
    Height: 36px;
    width: 188px;
    font-size: 12px;

    &::placeholder {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #687C97;
    }
  }

  span {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}

.dashboard-wrapper {
  .dashboard-left {
    width: 14.35vw;
    /*width: 300px;*/
    height: 100vh;
    transition: width .5s, height .5s;

    /* Portrait and Landscape */
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
      width: 15.35vw;
    }

    .logo {
      margin-top: .5em;
      display: flex;
      padding-bottom: 25px;
      border-bottom: 1px solid #EDF2F6;
      width: 100%;

      img {
        width: 50px;
        height: 50px;
      }

      h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #222B45;
        padding-left: 15px;
        padding-top: 15px;
        min-width: 50px;
        opacity: 1;
        transition: opacity .1s;
        transition-delay: .3s;
      }
    }

    .dashboard-menu {
      h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 8px;
        text-transform: uppercase;
        color: #687C97;
        margin-top: 20px;
        margin-bottom: 18px;
        min-width: 150px;
        @media screen and (max-device-width: 1400px) {
          font-size: 15.8px;
        }
        transition: font-size .5s;
        transition-delay: .2s;
      }

      .menu {
        img {
          width: 20px;
          height: 18px;
          margin-top: 7px;
          margin-right: 0.5rem;
        }

        ul {
          
          li {
            display: block;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 30px;
            color: #687C97;
            width: 100%;
            vertical-align: middle;
            padding: 6px 0px;
            cursor: pointer;
            border-left: 4px solid transparent;
            transition-delay: .2s;
            transition-duration: .5s;
            min-width: 100px;

            &:hover, &.active {
              border-left: 4px solid #F44059;
              margin-left: -24px;
              padding-left: 1.5rem;
            }

            span {
              margin-right: 0px !important;
              transition: font-size .3s linear .2s, margin-left .2s;
            }

            label {
              cursor: pointer;
              margin-left: 0px;
              transition: font-size .3s linear .2s, margin-left .2s;
            }
          }
        }
      }
    }

    .logout {
      margin-top: 1.2em;
      display: inline-block;
      border-top: 1px solid #EDF2F6;
      width: 15.1%;
      padding: 15px 30px;
      @media screen and (max-device-width: 1400px) {
        padding: 0px 30px;
      }

      label {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 30px;
        color: #687C97;
        width: 100%;
        vertical-align: middle;
        padding: 6px 0px;
        cursor: pointer;

        .logout-text {
          width: auto !important;
        }

        span {
          svg {
            margin-top: -3px;
          }
        }
      }
    }

    &.minimize {
      width: 100px;
      padding: 0px !important;
      transition: width .5s, height .5s;

      .logo {
        padding: 24px;

        h4 {
          opacity: 0;
          transition: opacity .1s;
        }
      }

      .dashboard-menu {
        h3 {
          font-size: 9.5px;
          transition-delay: 0s;
          text-align: left;
          margin-left: 6px;
        }

        .menu {

          ul {
            li {
              width: 100%;
              padding: 0px 10px;
              margin: 0;
              text-align: center;
              margin-bottom: 14px !important;
              transition-delay: 0s;
              min-width: 100px;

              span {
                margin-right: 0px !important;
                transition-delay: 0s;

                img {
                  width: 100%;
                  margin-right: 0.5rem;
                  transition-delay: 0s;
                }
              }

              label {
                font-size: 12px;
                width: 100%;
                transition-delay: 0s;
              }
            }
          }
        }
      }

      .logout {
        width: 4.9%;
        text-align: center;

        /* Portrait and Landscape */
        @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
          width: 7.4%;
        }

        span {
          margin: 0px !important;
        }

        .logout-text {
          font-size: 12px;
        }
      }
    }
  }

  .dashboard-right {
    width: calc(100% - 22em);
    float: left;
    margin-left: 30px;
    height: 97vh;
    transition: width .5s, height .5s;
    height: calc(100vh - 30px);

    /* ----------- Retina Screens ----------- */
    @media screen and (min-device-width: 2000px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
      width: calc(83.6% - 0%);
    }

    /* Portrait and Landscape */
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
      width: calc(100% - 16em);
    }

    .nav {
      cursor: pointer;
    }

    .section-title {
      display: flex;
      margin-left: 3em;
      margin-top: -3px;

      h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.2px;
        color: #1E1E1E;
      }
    }

    .user-icon {
      span {
        width: 30px;
        height: 30px;
        background-color: #bbbbbb;
        display: inherit;
        border-radius: 50%;
        border: 2px solid #fff;
        box-shadow: 0.5px 0.5px 5px 0px #bbb;
      }
    }

    .pb-4-5 {
      padding-bottom: 4.5em !important;

      &.border-bottom {
        border-bottom: 1px solid transparent !important;
      }
    }

    .error-box {
      background: #FFFFFF;
      border: 1px solid #FF1637;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

      .exclamation {
        background: #FF1637;
        padding: 8px 10px;
        display: inline-block;
        padding: 8px 10px;
      }

      label {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        letter-spacing: 1px;
        color: #EF314C;
        padding: 4px 10px 4px 30px;
      }

      .close {
        text-align: center;
        vertical-align: middle;
        color: #EF314C;
        padding: 4px 10px 4px 10px;
        margin-top: -4px;
        display: inline-block;

      }
    }

    .search-box {
      background: #FFFFFF;
      border: 1px solid #EDF2F6;
      box-sizing: border-box;
      box-shadow: 0px 10px 15px rgba(202, 215, 227, 0.114811);
      border-radius: 8px;
      padding: 15px 20px;
      display: inline-block;

      .search-main {
        input {
          width: 300px;
        }

        span {
          top: 20px;
          right: 51px;
        }
      }

      select {
        border: 1px solid #F4F7F9;
        box-sizing: border-box;
        border-radius: 8px;
        Height: 36px;
        width: 145px;
        font-size: 12px;
        color: #687C97;
      }
    }

    .btn-danger {
      background-color: #F44059 !important;
      border-color: #F44059 !important;
      border-radius: 7.93673px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      padding: 14px;
      vertical-align: middle;

      &:hover {
        background-color: #ec3b54 !important;
        border-color: #ec3b54 !important;
      }
    }

    .btn-outline-danger {
      background-color: #Ffffff !important;
      border-color: #F44059 !important;
      color: #F44059;
      border-radius: 7.93673px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      padding: 14px;
      vertical-align: middle;

      &:hover {
        background-color: #ec3b54 !important;
        border-color: #ec3b54 !important;
        color: white;
      }
    }

    .dashbord-scroll {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .height172 {
      height: calc(100vh - 172px);
    }

    .height260 {
      height: calc(100vh - 260px);
    }

    .dashboard-content-wrapper {
      /*height: 72vh;*/
      border-radius: 8px;

      .card-header {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #687C97;
        padding: 15px;
        background-color: #fff !important;
        border-bottom: 1px solid #EDF2F6;

        label {
          font-size: 14px;
          line-height: 38px;
          color: #687C97;
        }

      }
    }

    &.btn-error-show-hide {
      .dashboard-content-wrapper {

        .bg-height {
          height: calc(100vh - 265px);
          overflow-x: hidden;
          overflow-y: auto;
        }
      }

      .border-bottom {
        border-bottom: 1px solid #dee2e6 !important;
        margin-bottom: 0px !important;
      }
    }

  }

  .minimize + .dashboard-right {
    width: calc(100% - 9.5em);
    transition: width .5s, height .5s;
  }

  /*start-scrollbar-custom */
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px #e5e5e5;*/
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FF1637;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FF1637;
    cursor: pointer;
  }

  /*end-scrollbar-custom */

}

.parachute-detail-page {
  .parachute-select {
    width: 440px;
    height: calc(100vh - 270px);
    overflow: auto;

    .item-img {
      width: 348px;
      height: 140px;
    }

    .select-card {
      margin-top: 20px;

      .accordion-item {
        background: #FFFFFF;
        border-radius: 25px;
        /*width: 348px;*/
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        margin-bottom: 14px;
        border: none;

        &.missing-items {

          &.sub-component {
            margin-left: 30px;
          }
          .accordion-header {
            border: 2px solid transparent !important;
            cursor: default;

            &:hover, .active {
              border: 2px solid transparent !important;
              cursor: default !important;
            }
          }

          .item-text {
            h4 {
              opacity: .5;
            }
          }
        }

        .accordion-header {
          background: #FFFFFF;
          border-radius: 25px;
          height: 120px !important;
          width: 420px;
          display: flex;
          min-width: 0;
          word-wrap: break-word;
          background-clip: border-box;
          border: 2px solid transparent;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

          &:hover, &.active {
            border: 2px solid #f44059;
            cursor: pointer;
          }

          &.accordion-button:not(.collapsed)::after {
            transform: rotate(-90deg);
            filter: invert(24%) sepia(12%) saturate(0%) hue-rotate(
                            224deg
            ) brightness(100%) contrast(25%);

          }

          .cell-card {
            height: 66px;
            float: left;
            width: 44px;
            text-align: center;

            .cell {
              border: 1px solid #FF1637;
              box-sizing: border-box;
              border-radius: 5px;
              height: 52px;
              width: 18px;
              background: transparent;
              padding: 0px 1px 1px 1px;
              margin: auto;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              span {
                background: #FF1637;
                border-radius: 2px;
                height: 6px;
                width: 14px;
                display: block;
                margin-top: 1px;
                margin-bottom: 1px;
              }

              &.safe {
                border: 1px solid #00B929;

                span {
                  background: #00B929;
                }
              }

              &.safe + label {
                color: #00B929;
              }

              &.intermediary {
                border: 1px solid #FFCC16;

                span {
                  background: #FFCC16;
                }
              }

              &.intermediary + label {
                color: #FFCC16;
              }

              &.replace {
                border: 1px solid #FF1637;

                span {
                  background: #FF1637;
                }
              }

              &.replace + label {
                color: #FF1637;
              }
            }

            label {
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              font-size: 8px;
              line-height: 12px;
              letter-spacing: 0.4px;
              color: #FF1637;
              display: block;
              margin-top: 5px;
            }
          }

          .item-text {
            margin-left: 15px;

            h4 {
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.4px;
              color: #333333;
              margin-bottom: 1px;
            }

            h6 {
              font-family: Poppins;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.4px;
              color: #FF1637;
              margin-bottom: 1px;

              span {
                color: #FF1637;
              }

              .clickable {
                cursor: pointer;
              }

              .binIntermediary {
                color: #FFCC17;
              }

              .binSafe {
                color: #00B929;
              }

              .inspection {
                color: #FFCC17;
                cursor: pointer;
              }
            }
          }

          .exclamation {
            position: absolute;
            top: 6px;
            right: 8px;
          }
        }

        .accordion-body {
          padding: 0rem 1.25rem;
        }

        /* &.safe {
           .accordion-header {
             .cell-card {
               .cell {
                 border: 1px solid #00B929;

                 span {
                   background: #00B929;
                 }
               }

               label {
                 color: #00B929;
               }
             }

             .item-text {
               h6 {
                 color: #00B929;
               }
             }
           }
         }

         &.intermediary {
           .accordion-header {
             .cell-card {
               .cell {
                 border: 1px solid #FFCC16;

                 span {
                   background: #FFCC16;
                 }
               }

               label {
                 color: #FFCC16;
               }
             }

             .item-text {
               h6 {
                 color: #FFCC16;
               }
             }
           }
         }

         &.replace {
           .accordion-header {
             .cell-card {
               .cell {
                 border: 1px solid #FF1637;

                 span {
                   background: #FF1637;
                 }
               }

               label {
                 color: #FF1637;
               }
             }

             .item-text {
               h6 {
                 color: #FF1637;
               }
             }
           }
         }*/
      }

      .left-20 {
        margin-left: 20px;
      }

      .accordion-item-sub {
        border-radius: 25px;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        z-index: 500;

        .accordion-header {
          background: #FFFFFF;
          border-radius: 25px;
          height: 120px;
          width: 420px;
          display: flex;
          min-width: 0;
          word-wrap: break-word;
          box-shadow: none;

          &.accordion-button:not(.collapsed)::after {
            transform: rotate(-90deg);
            filter: invert(24%) sepia(12%) saturate(0%) hue-rotate(
                            224deg
            ) brightness(100%) contrast(25%);
          }

          .cell-card {
            height: 66px;
            float: left;
            width: 44px;
            text-align: center;

            .cell {
              border: 1px solid #FF1637;
              box-sizing: border-box;
              border-radius: 5px;
              height: 52px;
              width: 18px;
              background: transparent;
              padding: 0px 1px 1px 1px;
              margin: auto;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              span {
                background: #FF1637;
                border-radius: 2px;
                height: 6px;
                width: 14px;
                display: block;
                margin-top: 1px;
                margin-bottom: 1px;
              }

              &.safe {
                border: 1px solid #00B929;

                span {
                  background: #00B929;
                }
              }

              &.safe + label {
                color: #00B929;
              }

              &.intermediary {
                border: 1px solid #FFCC16;

                span {
                  background: #FFCC16;
                }
              }

              &.intermediary + label {
                color: #FFCC16;
              }

              &.replace {
                border: 1px solid #FF1637;

                span {
                  background: #FF1637;
                }
              }

              &.replace + label {
                color: #FF1637;
              }

            }

            label {
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              font-size: 8px;
              line-height: 12px;
              letter-spacing: 0.4px;
              color: #FF1637;
              display: block;
              margin-top: 5px;
            }
          }

          .item-text {
            margin-left: 15px;

            h4 {
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.4px;
              color: #333333;
              margin-bottom: 1px;
            }

            h6 {

              font-family: Poppins;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.4px;
              color: #FF1637;
              margin-bottom: 1px;

            }
          }

          .exclamation {
            position: absolute;
            top: 6px;
            right: 8px;
          }
        }

        /* &.safe {
           .accordion-header {
             .cell-card {
               .cell {
                 border: 1px solid #00B929;

                 span {
                   background: #00B929;
                 }
               }

               label {
                 color: #00B929;
               }
             }

             .item-text {
               h6 {
                 color: #00B929;
               }
             }
           }
         }

         &.intermediary {
           .accordion-header {
             .cell-card {
               .cell {
                 border: 1px solid #FFCC16;

                 span {
                   background: #FFCC16;
                 }
               }

               label {
                 color: #FFCC16;
               }
             }

             .item-text {
               h6 {
                 color: #FFCC16;
               }
             }
           }
         }

         &.replace {
           .accordion-header {
             .cell-card {
               .cell {
                 border: 1px solid #FF1637;

                 span {
                   background: #FF1637;
                 }
               }

               label {
                 color: #FF1637;
               }
             }

             .item-text {
               h6 {
                 color: #FF1637;
               }
             }
           }
         }*/
      }
    }
  }

  .separator {
    border-right: 2px solid #edf2f6;
    height: 72vh;
    margin-top: 40px;
    margin-left: 10px;
  }

  .parachute-description {
    /*margin-left: 155px;*/

    .item-img {
      width: 348px;
      height: 140px;
    }

    .description-card {
      margin-top: 20px;

      .card-top {
        background: #FFFFFF;
        border-radius: 25px;
        height: 120px;
        width: 400px;
        display: flex;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 14px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-content: stretch;
        justify-content: space-evenly;
        align-items: center;


        .serial, .dom {
          margin-top: 10px;

          span {
            width: 40px;
            height: 40px;
            background: #F0324D;
            padding: 6px;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            margin-right: 16px;
          }

          h6 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.4px;
            color: #333333;
            margin-bottom: 3px;
          }

          label {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.4px;
            color: #77838F;
            margin-bottom: 3px;
            white-space: nowrap;
            width: 67%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

      }

      .card-bottom {
        background: #FFFFFF;
        border-radius: 25px;
        Width: 400px;
        Height: 151px;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 14px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .description-list {
          margin-bottom: 15px;

          span {
            height: 9px;
            width: 9px;
            border-radius: 50%;
            display: inline-block;
            margin-top: 6px;
            margin-right: 4px;
          }

          h6 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.4px;
            color: #333333;
            margin-bottom: 0px;
          }

          label {
            font-family: Poppins;
            font-style: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.4px;
            color: #77838F;
            padding-left: 13px;

            span {
              width: unset;
            }
          }
        }

        .w-185px {
          width: 185px;
        }

        .w-120px {
          width: 120px;
        }
      }

    }

    .mr-70 {
      margin-right: 70px !important;
    }

    .w-180px {
      width: 180px !important;
    }

    .btn-danger {
      width: 157px;
    }
  }

  .w-48 {
    width: 48%;
    flex-direction: column;
    align-items: center;
  }

  .ps-2-2 {
    padding-left: 2.2rem !important;
  }

  /*start-scrollbar-custom */
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px #e5e5e5;*/
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FF1637;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FF1637;
    cursor: pointer;
  }

  /*end-scrollbar-custom */
}

.user-page {
  .user-table {
    .table-header-top {
      padding: 15px 20px;
      background: white;
      display: inline-block;
      width: 100%;

      h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #222B45;
        margin-bottom: 0px;
      }

      h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #687C97;
        margin-bottom: 0px;
        padding-top: 5px;

        span {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #222B45;
        }
      }
    }

    .table-header, .table-body {
      background: #F9FBFD;
      mix-blend-mode: normal;
      height: 48px;
      vertical-align: middle;
      width: 100%;
      border-top: 1px solid #EDF2F6;
      border-bottom: 1px solid #EDF2F6;
      opacity: inherit;
      border-radius: 8px;

      h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #687C97;
        padding: 16px 20px;
        margin-bottom: 0px;
      }

      .uno {
        width: 7.2%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .user-name {
        width: 33.8%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;

        span {
          background: #BDC9D3;
          width: 32px;
          height: 32px;
          margin-left: 20px;
          margin-top: 14px;
          margin-right: 6px;
        }
      }

      .profile-picture {
        height: 30px;
        width: auto;
        align-self: center;
        overflow: hidden;
        position: relative;
      }

      .email {
        width: 25%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .phone {
        width: 18%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .role {
        width: 15%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .action {
        width: 14.8%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media screen and (max-device-width: 1400px) {
          width: 19%;
        }

        img {
          width: 25px;
          height: 25px;
          margin-top: 14px;
        }
      }
    }

    .table-body {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      background: #ffffff;
      height: auto;
      opacity: inherit;
      overflow: auto;
      max-height: calc(100vh - 462px);

      h4 {
        color: #222B45;
        padding: 23px 20px;
      }

      .user-list {
        border-bottom: 1px solid #EDF2F6;
        height: 62px;

        .action {
          img {
            cursor: pointer;
          }
        }
      }
    }

    .table-pagination {
      padding: 40px 50px 0px;
      position: absolute;
      width: 100%;
      bottom: 5px;

      label {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #687C97;
      }

      span {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #687C97;
        padding: 12px;

        &.active {
          border-bottom: 1px solid #f44059;

        }
      }
    }
  }

  /*start-scrollbar-custom */
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px #e5e5e5;*/
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FF1637;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FF1637;
    cursor: pointer;
  }

  /*end-scrollbar-custom */
}

.user-history-page {

  padding: 25px;

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222B45;
  }

  label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
  }

  .rig-nav-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;

    .user-history-list-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      max-height: calc(100vh - 370px);
      width: 820px;
      padding-top: 0.3em;
    }

    .user-history-list {
      background: #FFFFFF;
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-clip: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 14px;
      width: 800px;
      height: 82px;

      .user-list {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 16px 25px;

        .count {
          width: 100px;
          text-align: start;

          h4 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 26px;
            color: #77838F;
            margin-bottom: 0px;
          }
        }

        .item-img {
          width: 120px;
          text-align: start;
          padding: 0;

          img {
            width: 50px;
            height: 50px;
          }
        }

        .serial-number {
          text-align: start;
          padding: 0;

          h4 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            color: #1E1E1E;
            margin-bottom: 0px;
          }

          width: 300px;
        }

        .user-time {
          width: 200px;
          text-align: start;
          padding: 0;

          h4 {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 26px;
            color: #77838F;
            margin-bottom: 0px;
          }
        }
      }
    }

    .history-list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .table-pagination {
      width: 800px;
    }
  }

  .date-range {
    input {
      border: 1px solid #F4F7F9;
      box-sizing: border-box;
      border-radius: 8px;
      Height: 36px;
      width: 181.5px;
      font-size: 12px;
    }
  }

  .search-main {
    input {
      width: 260px;
    }
  }
}

.spare-parts-page {
}

.bin-page {
  /*start-scrollbar-custom */
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px #e5e5e5;*/
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FF1637;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FF1637;
    cursor: pointer;
  }

  /*end-scrollbar-custom */
}

.spare-parts-serviceable-page, .spare-parts-unserviceable-page {
  .spare-parts-table {
    .table-header-top {
      padding: 15px 20px;
      background: white;
      display: inline-block;
      width: 100%;
      border-radius: 8px;
      height: 50px;

      .search-main {
        margin-right: 30px;
        padding-left: 50px;

        span {
          padding-right: 30px;
        }
      }

      h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #222B45;
        margin-bottom: 0px;
      }

      h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #687C97;
        margin-bottom: 0px;
        padding-top: 5px;

        span {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #222B45;
        }

      }
    }

    .table-header, .table-body {
      background: #F9FBFD;
      mix-blend-mode: normal;
      height: 48px;
      vertical-align: middle;
      width: 100%;
      border-top: 1px solid #EDF2F6;
      border-bottom: 1px solid #EDF2F6;

      h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #687C97;
        padding: 16px 52px;
        margin-bottom: 0px;
      }

      .serial-number {
        width: 50%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;

        span {
          margin-left: 50px;
          margin-top: 8px;
          margin-right: 15px;

          img {
            height: 49px;
            width: 38px;
          }
        }
      }

      .assemblyId {
        border-right: 1px solid #EDF2F6;
        padding: 0px;

        span {
          margin-left: 50px;
          margin-top: 8px;
          margin-right: 15px;

          img {
            height: 49px;
            width: 38px;
          }
        }
      }

      .spare-parts-time {
        width: 50%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .category {
        width: 15%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .operation {
        width: 15%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .description {
        width: 55%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }

      .created-at {
        width: 15%;
        border-right: 1px solid #EDF2F6;
        padding: 0px;
      }
    }

    .table-body {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      height: auto;
      opacity: inherit;
      border-radius: 8px;
      overflow: auto;
      max-height: calc(100vh - 462px);

      h4 {
        color: #222B45;
        padding: 23px 52px;
      }

      .spare-parts-list {
        border-bottom: 1px solid #EDF2F6;
        height: 72px;
        background: white;
        opacity: 1;
      }
    }

    .table-pagination {
      padding: 40px 50px 0px;
      position: absolute;
      width: 100%;
      bottom: 5px;

      label {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #687C97;
      }

      span {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #687C97;
        padding: 12px;

        &.active {
          border-bottom: 1px solid #f44059;

        }
      }
    }
  }

  /*start-scrollbar-custom */
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px #e5e5e5;*/
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FF1637;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FF1637;
    cursor: pointer;
  }

  /*end-scrollbar-custom */
}

.spare-parts-unserviceable-page {
  .table-header, .table-body {
    .serial-number {
      width: 30% !important;
      border-right: 1px solid #EDF2F6;
      padding: 0px;
    }

    .assemblyId {
      width: 50% !important;
      border-right: 1px solid #EDF2F6;
      padding: 0px;
    }

    .spare-parts-time {
      width: 50% !important;
      border-right: 1px solid #EDF2F6;
      padding: 0px;

      span {
        margin-left: 50px;
        margin-top: 8px;
        margin-right: 15px;

        img {
          height: 49px;
          width: 38px;
        }
      }
    }

    .spare-parts-action {
      width: 20% !important;
      border-right: 1px solid #EDF2F6;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
      }

      .btn-outline-danger {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
      }
    }

    .assembly-spare-parts-action {
      width: 50% !important;
      border-right: 1px solid #EDF2F6;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
      }

      .btn-outline-danger {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
      }
    }
  }
}

.rig-nav-tabs {
  nav {
    #nav-tab {
      border-bottom: 0px;

      .nav-link {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #687C97;
        background-color: transparent;
        border-color: transparent;
        padding: 15px 0 7px 0;
        margin-bottom: 23px;
        margin-right: 38px;
        border-bottom: 2px solid transparent;

        &.active {
          background-color: transparent;
          border-color: transparent;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #F44059;
          border-bottom: 2px solid #f44059;

        }
      }
    }
  }

  .tab-content {
    border-radius: 8px;
    border: 1px solid #EDF2F6;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(202, 215, 227, 0.114811);
    height: calc(100vh - 260px);
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      background: #FFFFFF;
      border-radius: 12px;
      padding: 30px;

      .modal-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #F74A63;
        mix-blend-mode: normal;
      }

      .modal-body {
        p {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          color: #808080;
          mix-blend-mode: normal;
        }

        label, select {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #808080;
        }

        select {
          border-bottom: 1px solid rgba(167, 167, 167, 0.5) !important;
          border-radius: 0;
        }
      }

      .modal-footer {
        .btn {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 13px !important;
          line-height: 19px;
          text-align: center;
          padding-top: 13px !important;
          padding-bottom: 13px !important;
          border-radius: 10px;
        }
      }
    }
  }

  .mw-400 {
    max-width: 400px;
  }

  .mw-725 {
    max-width: 725px;
  }

  .w-160 {
    width: 160px;
  }

  .form-check-input[type=radio] {
    border-radius: 50% !important;
    border: 2px solid #f44058;
  }

  .form-check-input:checked {
    background-color: transparent;
  }

  .form-check-input:checked[type=radio] {
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(324deg
    ) brightness(104%) contrast(97%);
  }

  .user-history {
    .modal-content {
      padding: 0px;

      .modal-header {
        border-bottom: 1px solid #EDF2F6;
        padding: 18px 30px;

        .modal-title {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #222B45;
        }

        .btn-close {
          background: none !important;
          opacity: inherit;
          box-sizing: unset;
          width: unset;
          height: unset;
          border: 0;
          border-radius: 0;
          position: absolute;
          top: 12px;
          right: 20px;

          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .modal-body {
        padding: 18px 44px;

        h4 {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #222B45;
        }

        .rig-nav-tabs {
          max-height: 500px;

          .tab-content {
            border-radius: 0;
            border: none;
            box-sizing: border-box;
            box-shadow: none;
            height: 250px;

            .user-history-list {
              background: #FFFFFF;
              border-radius: 10px;
              position: relative;
              display: flex;
              flex-direction: column;
              min-width: 0;
              word-wrap: break-word;
              background-clip: border-box;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
              margin-bottom: 14px;
              width: 635px;
              height: 82px;

              .user-list {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                padding: 16px 25px;

                .count {
                  width: 100px;
                  text-align: start;

                  h4 {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 26px;
                    color: #77838F;
                    margin-bottom: 0px;
                  }
                }

                .item-img {
                  width: 120px;
                  text-align: start;
                  padding: 0;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }

                .serial-number {
                  text-align: start;
                  padding: 0;

                  h4 {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 26px;
                    color: #1E1E1E;
                    margin-bottom: 0px;
                  }

                  width: 200px;
                }

                .user-time {
                  width: 160px;
                  text-align: start;
                  padding: 0;

                  h4 {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 26px;
                    color: #77838F;
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
        }

        .date-range {
          input {
            border: 1px solid #F4F7F9;
            box-sizing: border-box;
            border-radius: 8px;
            Height: 36px;
            width: 181.5px;
            font-size: 12px;
          }
        }

        .search-main {
          input {
            width: 260px;
          }
        }
      }

      .modal-footer {
        padding: 0px 30px 18px;

        .btn {
          padding-top: 10px !important;
          padding-bottom: 10px !important;
        }
      }
    }
  }

  .add-user {
    .modal-content {
      padding: 0px;

      .modal-header {
        border-bottom: 1px solid #EDF2F6;
        padding: 18px 30px;

        .modal-title {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #222B45;
        }

        .btn-close {
          background: none !important;
          opacity: inherit;
          box-sizing: unset;
          width: unset;
          height: unset;
          border: 0;
          border-radius: 0;
          position: absolute;
          top: 12px;
          right: 20px;

          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .modal-body {
        padding: 60px 70px;

        .form-floating {
          input, select {
            border-bottom: 1px solid #dedede;
            padding-bottom: 1.5em;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            border-radius: 0px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            color: #212529;
          }

          label {
            top: -12px !important;
            opacity: .70;
            left: -4px;
          }
        }

        .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
          opacity: 1 !important;
          transform: scale(1) translateY(-.5rem) translateX(.15rem);
          left: -4px !important;
          top: -20px !important;
        }

        .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
          padding-top: 1.625rem;
          border-bottom: 1px solid #f44059 !important;
        }

        .form-select:focus {
          border-bottom: 1px solid #f44059 !important;
          outline: 0;
          box-shadow: none !important;
        }
      }

      .modal-footer {
        padding: 0px 30px 18px;

        .btn {
          padding-top: 10px !important;
          padding-bottom: 10px !important;
        }
      }
    }
  }
}

nav {
  .breadcrumb {
    margin-left: 4.55em;

    li {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.175px;
      color: #77838F;
      cursor: pointer;

      a {
        color: #6e777f;
        text-decoration: none;

        &:hover {
          color: #77838F;
        }
      }

    }

    .breadcrumb-item + .breadcrumb-item::before {
      float: left;
      padding-right: .7rem;
      padding-left: .2rem;
      content: var(--bs-breadcrumb-divider, ">");
    }
  }
}

.form-floating {
  select {
    option {
      background: #fff;
      padding: 8px;

    }
  }
}

.item-empty {
  height: 62vh;
  width: 100%;
  flex-direction: column;

  h5 {
    font-size: 1.3em;
    font-family: "Poppins", sans-serif;
    color: #b1b5b8;
  }
}

.splash-page {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .footer-text {
    position: fixed;
    bottom: 5%;
  }

  h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    /*background: linear-gradient(to right, #ff0044 40%, #000000 57%);*/
    background: linear-gradient(to right, #ff0044 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5em;
  }

  h3 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.1em;
  }

  .logo {
    position: relative;
    animation: mymove .4s;
    animation-delay: .4s;
    animation-fill-mode: both;

    img {
      height: 135px;
      width: 135px;
    }
  }

  @keyframes mymove {
    from {
      top: -40px;
    }
    to {
      top: -20px;
    }
  }
}

.dragdrop {
  background: #F9F9F9;
  border: 1px dashed #E4E4E4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 102px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    &.icon {
      margin-top: 16px;
      margin-bottom: 10px;
    }

    &.text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #D3D3D3;
    }
  }

  img {
    &.profile-image {
      height: 100px;
      width: auto;
      border-radius: 5px;
      overflow: hidden;
    }
  }
}

.add-user-wrapper + .bg-white {
  &.dashboard-content-wrapper {
    height: calc(100vh - 240px);
  }

}

.form-switch {
  .form-check-input {
    border-radius: 2em !important;
    border: 1px solid #94a2b6;
    width: 2.3em;
    height: 1.3em;
    margin-top: .22em;

  }

  .form-check-label {
    margin-left: .8em;
  }
}
