.report-type-box {
    background-color: #e5e5e5;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    svg {
        display: flex;
        font-size: 19px;
        align-self: center;
    }
}

.header-row {
    display: flex;
    flex-direction: row;
    margin: 10px;

    .header-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        .btn-danger {
            width: 100px;
        }
    }

    .dropdown-container {
        width: 21%;
        justify-content: space-around;
    }

    .date-range-modal-body {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .date-range-container {

        .date-text {
            border: 1px solid #a2a2a2;
            padding: 5px;
            width: 116px;
            text-align: center;
            background: #f5f5f5;
            height: 36px;
        }

        .left {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            margin-right: 0 !important;
            margin-left: 10px;
        }

        .right {
            border-right: 0;
            margin-left: 0 !important;
            border-left: 0;
        }

        .down-arrow {
            height: 36px;
            margin-right: 10px;
            border: 1px solid #a2a2a2;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
}

.data-table {
    overflow-y: scroll;
}