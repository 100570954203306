.company-card {
    &.card {
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        width: '100%';
        height: 96px;
    
        .card-body {
          background: #ffffff;
          padding-left: 0;
          padding-right: 0;

            .card-cell-check-box-wrapper{
              display: flex;
              justify-content: center;
              align-items: center;
              max-width: 70px;
              .checkbox {
                height: 18px;
                width: 18px;
                align-content: center;
              }
            }

            .card-cell-image-box-wrapper{
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-items: center;
              margin-left: 0;
              width: 32%;

              .image {
                width: 48px;
                height: 48px;
                border-radius: 6px;
                margin-right: 16px;
                
              }

              .right-text-area {
                .name {
                  margin-top: 0;
                  margin-bottom: 8px;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 120%;
                  word-wrap: break-word;
                  word-break: break-all;
                }

                .phone {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 120%;
                  margin-top: 0;
                  margin-bottom: 0;
                  letter-spacing: 0.008em;
                  color: #92929D;
                }
              }
            }
            
            .card-cell-admin-fullName-wrapper{
              min-height: '100%';
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-items: center;
              width: 26%;

              .admin-fullName {
                margin-top: 0;
                margin-bottom: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 130%;
                letter-spacing: 0.008em;
                word-wrap: break-word;
                word-break: break-all;
              }
            }

            .card-cell-update-date-wrapper{
              min-height: '100%';
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-items: center;
              width: 15%;

              .update-date {
                margin-top: 0;
                margin-bottom: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 130%;
                letter-spacing: 0.008em;
                word-wrap: break-word;
              }
            }
          
            .card-cell-enable-button-wrapper {
              display: flex;
              justify-content: center;
              flex-direction: row;
              align-items: center;
              width: 10%;

              .btn-danger {
                padding-top: 8px;
                padding-bottom: 8px;
                max-width: 43px;
                margin-right: 5px;
              }
            }
        }
    }
}