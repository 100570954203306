.component-dropdown {
    position: relative;

    .menu {
        position: absolute;
        list-style-type: none;
        padding: 0;
        width: 150px;
        transform: translate3d(0px, 38px, 0px);
        top: 1px;
        left: 0px;
        will-change: transform;
        background-color: #ececec;
        border-radius: 2px;
        z-index: 1000;
    }

    .menu>li {
        margin: 0;
        background-color: transparent;
        width: 150px;
        z-index: 300;
    }

    .menu>li:hover {
        background-color: lightgray;
    }

    .menu>li>button {
        width: 100%;
        height: 100%;
        text-align: left;

        background: none;
        color: inherit;
        border: none;
        padding: 5px;
        margin: 0;
        font: inherit;
        cursor: pointer;
    }
}