body {
    margin: 0;
    background: #F3F6F9 !important;
}


textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
.is-Valid:focus {
    box-shadow: inset 0 -1px 0 #ddd;
    border: none;
    background: none;
}

. form-control:focus {
    box-shadow: none !important;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: transparent;
    box-shadow: none !important;
}