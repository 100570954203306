.item-card {
  &.card {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    Width: 220px;
    Height: 252px;

    .card-body {
      background: #ffffff;
      border-radius: 14px 14px 0px 0px;
      Height: 152.75px;

      span {
        &.exclamation {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
    }

    .card-footer {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      Height: 99.25px;
      border-top: none;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;

      &.replace {
        background: #FFEDED;
      }

      &.intermediary {
        background: #FFFED6;

        .count {
          background: #FFCC16;
        }
      }

      &.safe {
        background: #F8F8F8;

        .count {
          background: transparent;
          color: #222B45;
          display: none;
        }
      }

      .count {
        background: #FF1637;
        border-radius: 3px;
        padding: 3px 9px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
      }

      label {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #1E2022;
        margin-top: 5px;
        width: 100%;

        &.sigma-count {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #77838F;
          margin-top: 8px;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.5;
}