.detailRow-wrapper {
    margin-left: 10px;
    margin-top: 5px;
    background-color: #c5bcbcd6;
    padding-left: 8px;
    padding-bottom: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 10px;
    padding-top: 5px;
    display: flex;
    cursor: pointer;

    .detailRow-name {
        margin-left: 5px;
    }
}

.detail-pane-wrapper {
    margin-left: 10px;
    background-color: #e7e5e5d6;
    padding-left: 8px;
    padding-bottom: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 10px;
    padding-top: 5px;
    display: flex;
}

.main-component-wrapper {
    margin-left: 20px;
}

.sub-component-wrapper {
    margin-left: 40px;
}